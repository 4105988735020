if (!('console' in window)) {
    window.console = {};
    window.console.log = function(str){return str};
}

/**
 * array 拡張
 */
if (!Array.prototype.indexOf)
{
  Array.prototype.indexOf = function(elt /*, from*/)
  {
    var len = this.length;

    var from = Number(arguments[1]) || 0;
    from = (from < 0)
         ? Math.ceil(from)
         : Math.floor(from);
    if (from < 0)
      from += len;

    for (; from < len; from++)
    {
      if (from in this &&
          this[from] === elt)
        return from;
    }
    return -1;
  };
}
if (!Array.prototype.some) {
	  Array.prototype.some = function(fun /*, thisp */) {
	    "use strict";

	    if (this == null) throw new TypeError();

	    var t = Object(this),
	        len = t.length >>> 0;

	    if (typeof fun != "function") throw new TypeError();

	    var thisp = arguments[1];

	    for (var i = 0; i < len; i++) {
	      if (i in t && fun.call(thisp, t[i], i, t))
	        return true;
	    }

	    return false;
	  };
	}
if(!window.Float32Array){
	//console.log(Array);
	Array.prototype.set = function(a){
		var iLen = a.length;
		for(var i =0; i < iLen; i++){
			this.splice(i,i,a[i]);
		}
	}
	Float32Array = Array;
}

function sliceText(str,limit)
{
	//通常文字以外は終了
	if(!str || str==undefined || str == null)
	{
		return str;
	}
	
	//文字数指定なしは終了
	if(typeof limit !== "number" || limit < 0)
	{
		return str;
	}
	
	//指定文字数以下
	if(str.length <= limit){
		return str;
	}
	
	//全角、半角でlimitを分岐
	var strJudge = unescape(escape(str).replace(/%20/g,""));
	var aMatch = strJudge.match(/[^A-Za-z]+/);
	if(strJudge.match(/[^A-Za-z]+/))
	{
		//全角が含まれていれば文字数調整
		//limit = Math.floor(limit / 1.4);
	}
	
	//全体の文字数確認
	var strView = str;
	if(~~strView.length > limit)
	{
		//スペースを削除
		strView = strView.substr(0,limit-1);
		strView = unescape(escape(strView).replace(/%20+$/,""))+"…";
	}
	//alert(strView);
	return strView;
}

function year() {
    var data = new Date();
    var now_year = data.getFullYear();
    document.write(now_year);
}
/**
* scrollFrame
* facebookのiframe内でスクロール
* @param {int} iTarget ターゲットY座標
*/
function scrollFrame(iTarget){
	var iSpeed = 5;
	var iFric = 0.05;
	var iAccel = 0;
	var iDef = 0;
	var oData = FB.Canvas.getPageInfo(function(info){
		//現在の座標
		window.frame_pos_y = info.scrollTop;
		
		//ターゲット座標との差分で重みづけしてスクロール位置を決定
		iDef = window.frame_pos_y - iTarget;
		var iPosY = window.frame_pos_y - (iSpeed * (iDef * iFric));
		window.frame_pos_y = iPosY;
		
		//scroll
		if(window.frame_pos_y > 0){
			//更新
			FB.Canvas.scrollTo(0,window.frame_pos_y);
			setTimeout(scrollFrame, 10);
		}else{
			//終了
			FB.Canvas.scrollTo(0,0);
		}
	});
}

function nl2br(str)
{
	return unescape(escape(str).replace(/%0A|%0D|%0A%0D/g,"<br>"));
}

function escapeHTML(txt)
{
	txt = txt.replace(/</g, '&lt;');
    txt = txt.replace(/>/g, '&gt;');
    return txt;
}

function trim(str)
{
	//前後のスペースとタブを削除
	return unescape(escape(str).replace(/^(%u3000|%20|%09|%0D|%0A|%0D%0A)+|(%u3000|%20|%09|%0D|%0A|%0D%0A)+$/g, ""));
}

function keyCode(e){
	if(document.all)
		return  e.keyCode;
	else if(document.getElementById) 
		return (e.keyCode)? e.keyCode: e.charCode;
	else if(document.layers)
		return  e.which;
}


function printProperties(obj) {
    var properties = '';
    for (var prop in obj){
        properties += prop + "=" + obj[prop] + "\n";
    }
    alert(properties);
}

function dump(obj){
	printProperties(obj);
}

function getScale(iPhotoWidth,iPhotoHeight,iWindowWidth,iWindowHeight,iAjust)
{
	//比率
	iAjust = iAjust?iAjust:1.1;
	var img_yx = (iPhotoHeight / iPhotoWidth);
	var img_xy = (iPhotoWidth / iPhotoHeight);
	
	//リサイズ後のサイズ取得
	var iTargetWidth = 0;
	var iTargetHeight = 0;
	if((iWindowHeight / iWindowWidth) < img_yx)
	{
		if(iWindowWidth > iPhotoWidth)
		{ 
			iTargetWidth = iWindowWidth;
			iTargetHeight = iWindowWidth * img_yx;
		}else{  
			iTargetWidth = iPhotoWidth;
			iTargetHeight = iPhotoWidth * img_yx;
		}
	}else{
		if(iWindowHeight > iPhotoHeight)
		{ 
			iTargetWidth = iWindowHeight * img_xy;
			iTargetHeight = iWindowHeight;
		}else{  
			iTargetWidth = iPhotoHeight * img_xy;
			iTargetHeight = iPhotoHeight;
		}
	}	
	
	//補正
	iTargetWidth = iTargetWidth * iAjust;
	iTargetHeight = iTargetHeight * iAjust;
	
	var oRet = {};
	oRet["width"] = iTargetWidth;
	oRet["height"] = iTargetHeight;
	oRet["left"] = (iWindowWidth - iTargetWidth) / 2;
	oRet["top"] = (iWindowHeight - iTargetHeight) / 2;
	
	return oRet;
	
}

///**
// * extend function
// * @param {Object} s superclass
// * @param {Function} c constructor
// * 
//    Human = extend(Animal, function()
//	{
//	    // Human コンストラクタ
//	    // this.__super__.constructor(); ← でAnimalコンストラクタ呼出せる。
//	});
//	
//	Human.prototype.walk = function()
//	{
//	   this.__super__.walk();
//	   alert('teku, teku');
//	}
// */
//function extend(s, c)
//{
//	function f(){};
//	f.prototype = s.prototype;
//	c.prototype = new f();
//	c.prototype.__super__ = s.prototype;    // __super__のところを superclass とかにしてもOK!!
//         c.prototype.__super__.constructor = s;  // 上に同じく。但し、 super は予約語。
//	c.prototype.constructor = c;
//	return c;
//};
//
///**
// * extend function
// * @param {Object} s superclass
// */
//function clone(s)
//{
//   function f(){};
//   f.prototype = s;
//   return new f();
//}
//
///**
// * @param {String} s スーパクラス名(同一名前空間にある必要ある。)
// * @param {Function} c コンストラクター function Hoge() みたいに名前つける。
// * @param {Object} o メソッドとかプロパティ
// */
//function define(s, c, o)
//{
//    var i, p, n = c.toString().match(/^function ([^(]+)\(/)[1];
//    p = c.prototype = new this[s];
//    for(i in o){ p[i] = o[i]; };
//    this[n] = c;
//}

function getPromise(iTime){
    var oDfr = $.Deferred();
    setTimeout(function(){oDfr.resolve();},iTime);
    return oDfr.promise();
}

function sleep(iTime)
{
	getPromise(iTime);
}

/**
 * 簡易guid生成
 * @description Mathクラス拡張
 * @returns {string} guid
 */
Math.guid = function(){
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g,function(c){
		var r = Math.random() * 16 | 0, v = c == "x" ? r : (r&0x3|0x8);
		return v.toString(16);
	}).toUpperCase();
};

/**
 * バリデーション
 * @param value
 * @param msg
 */
function assert(value,msg){
	if(!value){
		throw(msg || (value + "はtrueではない"));
	}
}

/**
 * 比較バリデーション
 * @param val1
 * @param val2
 * @param msg
 */
function asserteq(val1,val2,msg){
	if(val1 !== val2){
		throw(msg || (val1 + "と" + val2 + "は等しくない"));
	}
}

/**
 * parseQuery
 * @param q {String} クエリ(?x=y&z=a)
 * @returns o {Object} クエリの連想配列
 */
function parseQuery(q){
	if(q){
		return eval(decodeURI(q.replace(/\?/,'({').replace(/=([^&]+)/g,function(){return ':"' + arguments[1] + '"'}).replace(/&/g,',') + '});'));
	}
	return {};
}

function getUA(){
	var o = {"ua":"","sp":false,"machine":""};
	var strUA = "";
	var strVer = "";
	strUA = navigator.userAgent.toLowerCase();
	strVer = navigator.appVersion.toLowerCase();
	if(strUA.indexOf("chrome") != -1){
		o["ua"] = "chrome";
		if(strUA.indexOf("android") != -1){
			o["machine"] = "android";
			o["sp"] = true;
		}
	}else if(strUA.indexOf("safari") != -1){
		o["ua"] = "safari";
		if(strUA.indexOf("iphone") != -1){
			o["machine"] = "iphone";
			o["sp"] = true;
		}else if(strUA.indexOf("ipad") != -1){
			o["machine"] = "ipad";
			o["sp"] = true;
		}else if(strUA.indexOf("ipod") != -1){
			o["machine"] = "ipod";
			o["sp"] = true;
		}else if(strUA.indexOf("android") != -1){
			o["machine"] = "android";
			o["ua"] = "android";
			o["sp"] = true;
		}
	}else if(strUA.indexOf("firefox") != -1){
		o["ua"] = "firefox";
	}else if(strUA.indexOf("opera") != -1){
		o["ua"] = "opera";
	}else if(strUA.indexOf("netscape") != -1){
		o["ua"] = "netscape";
	}else if(strUA.indexOf("msie") != -1){
		o["ua"] = "ie";
		  if (strVer.indexOf("msie 6.") != -1) {
			  o["ver"] = '6';
		  } else if (strVer.indexOf("msie 7.") != -1) {
			  o["ver"] = '7';
		  } else if (strVer.indexOf("msie 8.") != -1) {
			  o["ver"] = '8';
		  } else if (strVer.indexOf("msie 9.") != -1) {
			  o["ver"] = '9';
		  } else if (strVer.indexOf("msie 10.") != -1) {
			  o["ver"] = '10';
		  }  else {
		    return 'other';
		  }
	}else if(strUA.indexOf("trident") != -1){
		o["ua"] = "ie";
		o["ver"] = '11';
	}else if(strUA.indexOf("mozilla/4") != -1){
		o["ua"] = "mozilla/4";
	}
	if(strUA.indexOf("windows") != -1){
		o["machine"] = "win";
	}else if(strUA.indexOf("macintosh") != -1){
		o["machine"] = "mac";
	}
	if(strUA.indexOf("fban") != -1){
		o["sp"] = true;
	}
	if(strUA.indexOf("twitter") != -1){
		o["sp"] = true;
	}
	if(strUA.indexOf("iphone") != -1){
		o["sp"] = true;
	}
	return o;
}

function array_key_exists ( key, search ) {
    // http://kevin.vanzonneveld.net
    // +   original by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
    // +   improved by: Felix Geisendoerfer (http://www.debuggable.com/felix)
    // *     example 1: array_key_exists('kevin', {'kevin': 'van Zonneveld'});
    // *     returns 1: true
 
    // input sanitation
    if( !search || (search.constructor !== Array && search.constructor !== Object) ){
        return false;
    }
 
    return key in search;
}

/**
 * 素数生成
 * @param iNum
 * @returns
 */
function createPrimeNumbers(iNum){
	
	mojiretu = iNum + " = ";
	
	while (iNum >= 4 && iNum % 2 == 0){
		mojiretu = mojiretu + "2 * "; iNum /= 2;
	}
	
	var d = 3; 
	var q = iNum / d ;
	while (q >= d){
		if (iNum % d == 0){
			mojiretu = mojiretu + d + " * "; 
			iNum = q;
		}else{
			d += 2;
			q = iNum / d;
		}
		
	}
	mojiretu = mojiretu + iNum;
	
	return mojiretu;
}

/**
 * 約数
 * @param iNum
 */
function createDivisors(iNum){
	var a = [];
	var iDiv = 0;
	for(var i = 0; i <= iNum; i++){
		if(iNum%i == 0){
			a.push(i);
		}
	}
	return a;
}

function sbunkai(x)
{
	var x = x ;
	
	mojiretu = x + " = ";
	
	while (x >= 4 && x % 2 == 0){
		mojiretu = mojiretu + "2 * "; x /= 2;
	}
	
	var d = 3; var q = x / d ;
	while (q >= d){
		if (x % d == 0){
			mojiretu = mojiretu + d + " * "; x = q;
		}
		else d += 2;
		q = x / d;
	}
	mojiretu = mojiretu + x;
	
	return mojiretu;

}

function getExtention(fileName) {
	  var ret;
	  if (!fileName) {
	    return ret;
	  }
	  var fileTypes = fileName.split(".");
	  var len = fileTypes.length;
	  if (len === 0) {
	    return ret;
	  }
	  ret = fileTypes[len - 1];
	  return ret;
}

//String.prototype.toArray = function() {
//    var array = new Array;
//    for (var i=0 ; i < this.length; i++) {
//        array.push(this.charAt(i));
//    }
//    return array;
//};

function getToArray(a){
    var array = new Array;
    for (var i=0 ; i < a.length; i++) {
        array.push(a.charAt(i));
    }
    return array;
}

function shuffleArray(a){
    var i = a.length;
    while(i){
        var j = Math.floor(Math.random()*i);
        var t = a[--i];
        a[i] = a[j];
        a[j] = t;
    }
    return a;
}

function test(){
	var iLevel = 0;
	var iCount = 0;
	var iStart = ((Math.random()*(99-iCount))<<0)+1;
	var iAnswer = 0;
	var iAnswerCount = 0;
	var a = [];
	var aNum = [];
	var aAnswer = [];
	
	//レベル別の番号数
	//iLevel = ~~this.getSession("level");
	iLevel = 2;
	iCount = (iLevel+1)*20;
	
	//100個数字生成
	for(var i = 1; i < 100; i++){
		a.push(i);
	}
	
	//ランダム
	var iRand = 0;
	if(iRand === 0){
		a = shuffleArray(a);
	}
	
	//番号決定
	iStart = ((Math.random()*(99-iCount))<<0)+1;
	for(var j = 0; j < iCount; j++){
		aNum.push(a[j]);
	}
	
	//回答決定
	iAnswerCount = iLevel+1;
	for(var k = 0; k < iAnswerCount; k++){
		iAnswer = aNum[(Math.random()*iCount)<<0];
		if(aAnswer.indexOf(iAnswer) >= 0){
			//重複したら繰り返し
			k--;
			continue;
		}
		//回答に追加
		aAnswer.push(iAnswer);
	}
}


function smartRollover() {
	if(document.getElementsByTagName) {
		var images = document.getElementsByTagName("img");
		for(var i=0; i < images.length; i++) {
			if(images[i].getAttribute("src").match("_off."))
			{
				images[i].onmouseover = function() {
					this.setAttribute("src", this.getAttribute("src").replace("_off.", "_on."));
				}
				images[i].onmouseout = function() {
					if($(this).hasClass("current")){return;}
					this.setAttribute("src", this.getAttribute("src").replace("_on.", "_off."));
				}
			}
		}
	}
}

function removeSmartRollover() {
	if(document.getElementsByTagName) {
		var images = document.getElementsByTagName("img");

		for(var i=0; i < images.length; i++) {
			if(images[i].getAttribute("src").match("_off."))
			{
				images[i].onmouseover = null
				images[i].onmouseout = null
			}
		}
	}
}

/**
 * 非同期コマンドオブジェクト取得
 * @param arg
 * @returns
 */
function getAsyncCommand(arg){
	var aArgs = Array.prototype.slice.call(arg, 0);
	return aArgs.pop();
}

/**
 * decode_base64
 * @param s
 * @returns {String}
 */
function decode_base64(s) {
    var e={},i,k,v=[],r='',w=String.fromCharCode;
    var n=[[65,91],[97,123],[48,58],[47,48],[43,44]];

    for(z in n){for(i=n[z][0];i<n[z][1];i++){v.push(w(i));}}
    for(i=0;i<64;i++){e[v[i]]=i;}

    for(i=0;i<s.length;i+=72){
       var b=0,c,x,l=0,o=s.substring(i,i+72);
       for(x=0;x<o.length;x++){
         c=e[o.charAt(x)];b=(b<<6)+c;l+=6;
         while(l>=8){r+=w((b>>>(l-=8))%256);}
       }
    }
   return r;
 }

function getBlobCanvas(oCanvas,strMime){
    var dataUrl = oCanvas.toDataURL(strMime);
    return getBlobDataURL(dataUrl.split("base64,")[1]);
    
    /*
    var bin = atob(dataUrl.split("base64,")[1]);
    var len = bin.length;
    var barr = new Uint8Array(len);
    var oBlob = "";
    for(var i = 0; i<len; i++){
        barr[i] = bin.charCodeAt(i);
    }
    
    try{
    	oBlob = new Blob([barr],{type:strMime});
    }catch(e){
	    // TypeError old chrome and FF
	    //window.BlobBuilder = window.BlobBuilder || window.WebKitBlobBuilder || window.MozBlobBuilder || window.MSBlobBuilder;
    	window.BlobBuilder = (window.WebKitBlobBuilder) ? WebKitBlobBuilder : (window.MozBlobBuilder) ? MozBlobBuilder: BlobBuilder;
	    if(e.name == 'TypeError' && window.BlobBuilder){
	    	var byteString = atob(dataUrl.split(',')[1]);
	    	var ab = new ArrayBuffer(byteString.length);
	    	var ia = new Uint8Array(ab);
	    	for (var i = 0; i < byteString.length; i++) {
	    	   ia[i] = byteString.charCodeAt(i);
	    	}
	        var bb = new BlobBuilder();
	        bb.append(ab);
	        oBlob = bb.getBlob(strMime);
	    }
	    else if(e.name == "InvalidStateError"){
	        // InvalidStateError (tested on FF13 WinXP)
	    	oBlob = new Blob([barr.buffer],{type:strMime});
	    }
	    else{
	        // We're screwed, blob constructor unsupported entirely   
	    }
    }
    
//    if(typeof Blob !== "function"){
//    	var builder = new WebKitBlobBuilder();
//    	builder.append(barr.buffer);
//    	return builder.getBlob(strMime);
//    }
     */
    return oBlob;
}

function getBlobDataURL(strDataURL){
	var bin = atob(strDataURL);
    var len = bin.length;
    var barr = new Uint8Array(len);
    var oBlob = "";
    for(var i = 0; i<len; i++){
        barr[i] = bin.charCodeAt(i);
    }
    
    try{
    	oBlob = new Blob([barr],{type:strMime});
    }catch(e){
	    // TypeError old chrome and FF
	    //window.BlobBuilder = window.BlobBuilder || window.WebKitBlobBuilder || window.MozBlobBuilder || window.MSBlobBuilder;
    	window.BlobBuilder = (window.WebKitBlobBuilder) ? WebKitBlobBuilder : (window.MozBlobBuilder) ? MozBlobBuilder: BlobBuilder;
	    if(e.name == 'TypeError' && window.BlobBuilder){
	    	var byteString = atob(dataUrl.split(',')[1]);
	    	var ab = new ArrayBuffer(byteString.length);
	    	var ia = new Uint8Array(ab);
	    	for (var i = 0; i < byteString.length; i++) {
	    	   ia[i] = byteString.charCodeAt(i);
	    	}
	        var bb = new BlobBuilder();
	        bb.append(ab);
	        oBlob = bb.getBlob(strMime);
	    }
	    else if(e.name == "InvalidStateError"){
	        // InvalidStateError (tested on FF13 WinXP)
	    	oBlob = new Blob([barr.buffer],{type:strMime});
	    }
	    else{
	        // We're screwed, blob constructor unsupported entirely   
	    }
    }
    
    return oBlob;
}

function getByteArray(oCanvas,strMime){
    var dataUrl = oCanvas.toDataURL(strMime);
    var bin = atob(dataUrl.split("base64,")[1]);
    var len = bin.length;
    var barr = new Uint8Array(len);
    for(var i = 0; i<len; i++){
        barr[i] = bin.charCodeAt(i);
    }
    return barr;
}

//ブラウザに対応した呼び出し方のための関数
function swfName(str) {
	if (navigator.appName.indexOf("Microsoft") != -1) {
		return window[str];
	}else {
		return document[str];
	}
}

function priceFormat(num){
	if(/^(?:[0-9]+)*$/.exec(num)){ 
		return String( num ).replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,' );
	}else{
		return num; 
	}
}

var DateFormat = function(pattern) {
	  this._init(pattern);
	};

	DateFormat.prototype = {
	  _init: function(pattern) {

	    this.pattern = pattern;
	    this._patterns = [];

	    for (var i = 0; i < pattern.length; i++) {
	      var ch = pattern.charAt(i);
	      if (this._patterns.length == 0) {
	        this._patterns[0] = ch;
	      } else {
	        var index = this._patterns.length - 1;
	        if (this._patterns[index].charAt(0) == "'") {
	          if (this._patterns[index].length == 1 
	             || this._patterns[index].charAt(this._patterns[index].length - 1) != "'") {
	            this._patterns[index] += ch;
	          } else {
	            this._patterns[index + 1] = ch;
	          }
	        } else if (this._patterns[index].charAt(0) == ch) {
	          this._patterns[index] += ch;
	        } else {
	          this._patterns[index + 1] = ch;
	        }
	      }
	    }
	  },

	  format: function(date) {

	    var result = [];
	    for (var i = 0; i < this._patterns.length; i++) {
	      result[i] = this._formatWord(date, this._patterns[i]);
	    }
	    return result.join('');
	  },
	  _formatWord: function(date, pattern) {

	    var formatter = this._formatter[pattern.charAt(0)];
	    if (formatter) {
	      return formatter.apply(this, [date, pattern]);
	    } else {
	      return pattern;
	    }
	  },
	  _formatter: {
	    "y": function(date, pattern) {
	      // Year
	      var year = String(date.getFullYear());
	      if (pattern.length <= 2) {
	        year = year.substring(2, 4);
	      } else {
	        year = this._zeroPadding(year, pattern.length);
	      }
	      return year;
	    },
	    "M": function(date, pattern) {
	      // Month in year
	      return this._zeroPadding(String(date.getMonth() + 1), pattern.length);
	    },
	    "d": function(date, pattern) {
	      // Day in month
	      return this._zeroPadding(String(date.getDate()), pattern.length);
	    },
	    "H": function(date, pattern) {
	      // Hour in day (0-23)
	      return this._zeroPadding(String(date.getHours()), pattern.length);
	    },
	    "m": function(date, pattern) {
	      // Minute in hour
	      return this._zeroPadding(String(date.getMinutes()), pattern.length);
	    },
	    "s": function(date, pattern) {
	      // Second in minute
	      return this._zeroPadding(String(date.getSeconds()), pattern.length);
	    },
	    "S": function(date, pattern) {
	      // Millisecond
	      return this._zeroPadding(String(date.getMilliseconds()), pattern.length);
	    },
	    "'": function(date, pattern) {
	      // escape
	      if (pattern == "''") {
	        return "'";
	      } else {
	        return pattern.replace(/'/g, '');
	      }
	    },
	    "w": function(date, pattern) {
	    	var week = ["Sun","Mon","Thu","Wed","Thr","Fri","Sat"];
	    	return week[date.getDay()];
	    }
	  },

	  _zeroPadding: function(str, length) {
	    if (str.length >= length) {
	      return str;
	    }

	    return new Array(length - str.length + 1).join("0") + str;
	  },


	  /// Parser ///
	  parse: function(text) {

	    if (typeof text != 'string' || text == '') return null;

	    var result = {year: 1970, month: 1, day: 1, hour: 0, min: 0, sec: 0, msec: 0};

	    for (var i = 0; i < this._patterns.length; i++) {
	       if (text == '') return null; // parse error!!
	       text = this._parseWord(text, this._patterns[i], result);
	       if (text === null) return null; // parse error!!
	    }
	    if (text != '') return null; // parse error!!

	    return new Date(
	                result.year,
	                result.month - 1,
	                result.day,
	                result.hour,
	                result.min,
	                result.sec,
	                result.msec);
	  },
	  _parseWord: function(text, pattern, result) {

	    var parser = this._parser[pattern.charAt(0)];
	    if (parser) {
	      return parser.apply(this, [text, pattern, result]);
	    } else {
	      if (text.indexOf(pattern) != 0) {
	        return null;
	      } else {
	        return text.substring(pattern.length);
	      }
	    }
	  },
	  _parser: {
	    "y": function(text, pattern, result) {
	      // Year
	      var year;
	      if (pattern.length <= 2) {
	        year = text.substring(0, 2);
	        year = year < 70 ? '20' + year : '19' + year;
	        text = text.substring(2);
	      } else {
	        var length = (pattern.length == 3) ? 4 : pattern.length;
	        year = text.substring(0, length);
	        text = text.substring(length);
	      }
	      if (!this._isNumber(year)) return null; // error
	      result.year = parseInt(year, 10);
	      return text;
	    },
	    "M": function(text, pattern, result) {
	      // Month in year
	      var month;
	      if (pattern.length == 1 && text.length > 1
	          && text.substring(0, 2).match(/1[0-2]/) != null) {
	        month = text.substring(0, 2);
	        text  = text.substring(2);
	      } else {
	        month = text.substring(0, pattern.length);
	        text  = text.substring(pattern.length);
	      }
	      if (!this._isNumber(month)) return null; // error
	      result.month = parseInt(month, 10);
	      return text;
	    },
	    "d": function(text, pattern, result) {
	      // Day in month
	      var day;
	      if (pattern.length == 1 && text.length > 1 
	          && text.substring(0, 2).match(/1[0-9]|2[0-9]|3[0-1]/) != null) {
	        day  = text.substring(0, 2);
	        text = text.substring(2);
	      } else {
	        day  = text.substring(0, pattern.length);
	        text = text.substring(pattern.length);
	      }
	      if (!this._isNumber(day)) return null; // error
	      result.day = parseInt(day, 10);
	      return text;
	    },
	    "H": function(text, pattern, result) {
	      // Hour in day (0-23)
	      var hour;
	      if (pattern.length == 1 && text.length > 1
	          && text.substring(0, 2).match(/1[0-9]|2[0-3]/) != null) {
	        hour = text.substring(0, 2);
	        text = text.substring(2);
	      } else {
	        hour = text.substring(0, pattern.length);
	        text = text.substring(pattern.length);
	      }
	      if (!this._isNumber(hour)) return null; // error
	      result.hour = parseInt(hour, 10);
	      return text;
	    },
	    "m": function(text, pattern, result) {
	      // Minute in hour
	      var min;
	      if (pattern.length == 1 && text.length > 1
	          && text.substring(0, 2).match(/[1-5][0-9]/) != null) {
	        min  = text.substring(0, 2);
	        text = text.substring(2);
	      } else {
	        min  = text.substring(0, pattern.length);
	        text = text.substring(pattern.length);
	      }
	      if (!this._isNumber(min)) return null; // error
	      result.min = parseInt(min, 10);
	      return text;
	    },
	    "s": function(text, pattern, result) {
	      // Second in minute
	      var sec;
	      if (pattern.length == 1 && text.length > 1
	          && text.substring(0, 2).match(/[1-5][0-9]/) != null) {
	        sec  = text.substring(0, 2);
	        text = text.substring(2);
	      } else {
	        sec  = text.substring(0, pattern.length);
	        text = text.substring(pattern.length);
	      }
	      if (!this._isNumber(sec)) return null; // error
	      result.sec = parseInt(sec, 10);
	      return text;
	    },
	    "S": function(text, pattern, result) {
	      // Millimsecond
	      var msec;
	      if (pattern.length == 1 || pattern.length == 2) {
	        if (text.length > 2 && text.substring(0, 3).match(/[1-9][0-9][0-9]/) != null) {
	          msec = text.substring(0, 3);
	          text = text.substring(3);
	        } else if (text.length > 1 && text.substring(0, 2).match(/[1-9][0-9]/) != null) {
	          msec = text.substring(0, 2);
	          text = text.substring(2);
	        } else {
	          msec = text.substring(0, pattern.length);
	          text = text.substring(pattern.length);
	        }
	      } else {
	        msec = text.substring(0, pattern.length);
	        text = text.substring(pattern.length);
	      }
	      if (!this._isNumber(msec)) return null; // error
	      result.msec = parseInt(msec, 10);
	      return text;
	    },
	    "'": function(text, pattern, result) {
	      // escape
	      if (pattern == "''") {
	        pattern = "'";
	      } else {
	        pattern = pattern.replace(/'/g, '');
	      }
	      if (text.indexOf(pattern) != 0) {
	        return null; // error
	      } else {
	        return text.substring(pattern.length);
	      }
	    }
	  },

	  _isNumber: function(str) {
	    return /^[0-9]*$/.test(str);
	  }
	}
	
	function convDegToRad(deg){
		return deg*Math.PI/180;
	}

	function dtr(deg){
		return convDegToRad(deg);
	}
	
	function convRadToDeg(rad){
		return rad*180/Math.PI;
	}

	function rtd(rad){
		return convRadToDeg(rad);
	}