/**
 * JqManager
 * @class Page Manager
 * @returns
 */
var JqManager = Klass.create();

JqManager.extend(JqBase);

//definition start
JqManager.extend({
/**
 * init
 */
init:function(){
	this.scenes = [];
	this.titles = [];
	this.title_base = "";
	this.current = "";
	this.next = this.current;
	this.set();
},
/**
 * 設定
 */
set:function(){
	this.current = this.getSceneName();
	this.setObject();
	this.changeUrl(this.current);
},
/**
 * オブジェクト設定
 */
setObject:function(){
},
/**
 * 開始
 */
start:function(){
	var oCommand = new Command();
	oCommand.execute();
},
/**
 * preload
 */
preload:function(){
	var oThis = this;
	var aData = [];
	var oData = {};
	var oJson = this.loader.data.data;
	for(var j = 0; j < oJson.length; j++){
		oData = oJson[j];
		aData[j] = {"src":"/img/works/"+oData.photo,"id":"/img/works/"+oData.photo};
	}
	
	var oAsync = this.getAsyncCommand(arguments);
	var oPreloader = new createjs.LoadQueue(true);
	var manifest = [];
	var iLen = aData.length;
	for(var i = 0; i < iLen; i++){
		o = {};
		o.src = aData[i].src;
		o.id = aData[i].src;
		o.type = createjs.PreloadJS.IMAGE;
		manifest.push(o);
	}
	oPreloader.on("fileload",function(e) {
	},this);
	oPreloader.on("progress",function(e) {
		oThis.per_target = (e.loaded * 100) >> 0;
		oThis.loader.txt.o.html(oThis.per_target);
	},this);
	oPreloader.on("complete", function(e) {
		e.target.onFileLoad = null;
		e.target.onComplete = null;
		e.target.onProgress = null;
		oThis.per_target = 100;
		oThis.loader.txt.o.html(100);
	},this);
	oPreloader.loadManifest(manifest);
	oPreloader.load();
	
	//表示更新処理
	this.progress_timer = setTimeout(function(){
		oThis.updateProgress(oThis,oAsync);
	},50);
},
/**
 * progress
 */
updateProgress:function(oThis,oAsync){
	var iPerNow = ~~oThis.loader.txt.o.html();
	var iPer = (iPerNow + Math.ceil((oThis.per_target - iPerNow)*0.1));
	oThis.loader.txt.o.html(iPer);
	if(iPer >= 100){
		oThis.loader.txt.o.html(100);
		oAsync.publish();
		oThis.progress_timer = null;
	}else{
		oThis.progress_timer = setTimeout(function(){
			oThis.updateProgress(oThis,oAsync);
		},50);
	}
},
/**
 * イベントセット
 */
setEvent:function(){
},
/**
 * シーンクリア
 */
clearScene:function(){
	this.primary.empty();
},
/**
 * シーン情報更新
 * @param {JqController} scene ページオブジェクト
 * @param {String} query クエリ
 */
updateScene:function(scene){
    this.changeUrl(this.next);
    this.header.setTitle(this.titles[this.current]);
    this.scene = null;
    this.scene = scene;
},
/**
 * シーン追加
 */
addScene:function(lbl,obj){
	this.scenes[lbl] = obj;
},
/**
 * シーン設定
 */
setScene:function(strScene){
	var mng = window._manager;
	mng.header.menu.select(strScene);
	this.changeUrl(strScene,"");
	var fnScene = this.scenes[this.current]; 
	this.scene = fnScene.create(this,this.current,this.page);
},
/**
 * シーン移動
 */
gotoScene:function(strScene){
	var mng = window._manager;
	mng.parallax.scroll(~~mng.scenes[strScene]);
	mng.header.menu.select(strScene);
},
/**
 * ページ遷移
 * @params {String}　strPage　ページ名
 * @params {String}　strMode　トランジションモード
 * @params {String}　iTime　秒数
 */
changePage:function(strPage,strMode,iTime){
	if(strMode == undefined){
		strMode = "slideright";
	}
	
	if(iTime == undefined){
		iTime = 500;
	}
	
	if(this.scene.jq){
		this.scene.jq.goTo("#"+strPage,{reverse:true,transition:strMode,scrollTime:iTime});
		this.changeUrl(this.current);
		this.header.setTitle(this.titles[this.current]);
	}
},
/**
 * 現在のシーンとページ情報を更新
 */
updateUrl:function(next){
    this.current = next;
    //console.log(this.current);
},
/**
 * url変更
 */
changeUrl:function(scene,query){
	query ? query = "?" + query : query = "";
	if(scene !== this.current){
		this.updateUrl(scene);
		if(history.pushState){
			history.replaceState(scene);
			history.pushState(scene, document.title, "#/" + scene + query);		
		}else{
			location.hash = "#/" + scene + query;
		}
		this.header.setTitle(this.titles[this.current]+" | "+ this.title_base);
	}
},
/**
 * タイトル追加
 */
addTitle:function(lbl,str){
	this.titles[lbl] = str;
},
/**
 * scene名取得
 * @retrun {String} シーン名
 * @description scene=scene_name
 */
getSceneName:function(){
	var oURL = $.url();
	var aPath = oURL.data.seg.fragment;
	//var aPath = oURL.data.seg.path;
	return ((aPath[0] === undefined) || (aPath[0] === "") || (aPath[0] === null) || array_key_exists(aPath[0],this.scenes) == false) ? "top" : aPath[0];
},
/**
 * sceneに紐づく値を取得
 * @retrun {String} シーン名
 * @description scene=scene_name
 */
getSceneVal:function(){
	var oURL = $.url();
	var aPath = oURL.data.seg.fragment;
	//var aPath = oURL.data.seg.path;
	return ((aPath[0] === undefined) || (aPath[0] === "") || (aPath[0] === null) || array_key_exists(aPath[0],this.scenes) == false) ? this.scenes["default"] : this.scenes[aPath[0]];
},
/**
 * ページ名取得
 * @retrun {String} ページ名
 * @description #page_name
 */
getPageName:function(){
	var iQ = location.hash.search("\\?");
	var p = location.hash.substring(0,iQ).replace("#","").split("/");
	return !p[1] ? "" : p[1];
},
/**
 * google analytics計測
 * [GA]
 * ga:mode = _trackPageview,_trackEvent
 * ga:category = pv:ページ名,event:カテゴリ名
 * ga:action = pv:なし,event:アクション名
 * ga:label = pv:なし,event:ラベル名
 * ga:val = pv:なし,event:値
 * ga:href = 遷移先
 * [UA]
 * ua:mode = pageview,event
 * ua:category = pv:ページ名,event:カテゴリ名
 * ua:action = pv:なし,event:アクション名
 * ua:label = pv:なし,event:ラベル名
 * ua:val = pv:なし,event:値
 */
postGA:function(param){
	var bEvent = false;
	
	if(!ga||ga==null||ga==undefined||ga==""){
		return;
	}
	
	switch(param["type"]){
	case "ua":
		if(param["mode"] === "event"){
			ga("send",param["mode"],param["category"],param["action"],param["label"],param["val"]);
			bEvent = true;
		}else{
			ga("send",param["mode"],param["category"]);
		}
		break;
	case "ga":
		if(param["mode"]  === "_trackEvent"){
			_gaq.push([param["mode"],param["category"],param["action"],param["label"],param["val"]]);
			bEvent = true;
		}else{
			_gaq.push([param["mode"],param["category"]]);
		}
		break;
	}
	
	//イベント
	if(bEvent&&param["href"]){
	  setTimeout(function() {
		  location.href = param["href"];
	  }, 1000);
	}
},
track:function(e){
	//console.log("track");
	var $target = $(e.currentTarget);
	var o = {
		type:$target.attr("data-type"),
		target:$target.attr("target"),
		href:$target.attr("href"),
		mode:$target.attr("data-mode"),
		category:$target.attr("data-category"),
		action:$target.attr("data-action"),
		label:$target.attr("data-label"),
		val:~~$target.attr("data-val")
	};
	_manager.postGA(o);
	if(o.target !== "_blank"){
		return false;
	}
},
});
//definition end