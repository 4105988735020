/**
 * Validator
 * @class validator
 * @returns
 */
var Validator = Klass.create();

//definition start
Validator.extend({
/**
 * 入力
 */
required:function(val,b){
	if(b){
		if(val.replace(/[\s\n\r\t]/g,"")){ return true; } return false;
	}else{
		if(val){ return true; } return false;
	}
},
/**
 * 選択
 */
select:function(val){
},
/**
 * 一致
 */
retype:function(val1,val2){
	if(val1 === val2){ return true; } return false; 
},
/**
 * メールアドレス
 */
mail:function(val){
	if(/^(?:[^\@]+?@[A-Za-z0-9_\.\-]+\.+[A-Za-z\.\-\_]+)*$/.exec(val)){ return true; } return false; 
},
/**
 * 半角
 */
hankaku:function(val){
	if(/^(?:[a-zA-Z0-9@\<\>\;\:\[\]\{\}\|\^\=\/\!\*\`\"\#\$\+\%\&\'\(\)\,\.\-\_\?~\\\s]*)*$/.exec(val)){ return true; } return false; 
},
/**
 * 全角
 */
zenkaku:function(val){
	if(/^(?:[^a-zA-Z0-9@\<\>\;\:\[\]\{\}\|\^\=\/\!\*\"\#\$\+\%\&\'\(\)\,\.\-\_\?\\\s]+)*$/.exec(val)){ return true; } return false; 
},
/**
 * ひらがな
 */
hiragana:function(val){
	if(/^(?:[ぁ-ゞ]+)*$/.exec(val)){ return true; } return false; 
},
/**
 * カタカナ
 */
katakana:function(val){
	if(/^(?:[ァ-ヾ]+)*$/.exec(val)){ return true; } return false; 
},
/**
 * 半角英数
 */
alphanum:function(val){
	if(/^(?:[a-zA-Z0-9]+)*$/.exec(val)){ return true; } return false; 
},
/**
 * 半角英数(小文字)
 */
alphanuml:function(val){
	if(/^(?:[a-z0-9]+)*$/.exec(val)){ return true; } return false; 
},
/**
 * 数字
 */
number:function(val){
	if(/^(?:[0-9]+)*$/.exec(val)){ return true; } return false; 
},
/**
 * 最小桁数
 */
min:function(val,i){
	if(~~((val+"").length) > ~~i){ return true; } return false;
},
/**
 * 最大桁数
 */
max:function(val,i){
	if(~~((val+"").length) < ~~i){ return true; } return false;
},
/**
 * パスワード
 */
password:function(val){
	if(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[\$\@\#\!%\^\*\&\'\:\(\)\+\?\;\=\,\]\[\~\>\<\|\{\}])[0-9a-zA-Z\$\@\#\!%\^\*\&\'\:\(\)\+\?\;\=\,\]\[\~\>\<\|\{\}]{8,}$/.exec(val)){ return true; } return false;
},
/**
 * 日付
 */
date:function(val){
	if(/^(?:[0-9]{4}\/[0-9]{2}\/[0-9]{2})*$/.exec(val)){ return true; } return false;
},
/**
 * 年齢制限
 * @param val yyyy/mm/dd
 */
age:function(val,min,max){
	var dtNow = new Date();
	var iAge = (((~~(""+dtNow.getFullYear()+""+("0"+(dtNow.getMonth()+1)).slice(-2)+""+("0"+dtNow.getDate()).slice(-2))) - ~~(val.replace(/\//g,"")))/10000 >> 0);
	if(min <= iAge && max >= iAge){ return true; } return false;
},
/**
 * 郵便番号
 */
zip:function(val){
	if(/^(?:\d{3}-?\d{4}$|^\d{3}-?\d{2}$|^\d{3}$)*$/.exec(val)){ return true; } return false;
},
/**
 * URL
 */
url:function(val){
	if(/^(?:(?:ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?)*$/.exec(val)){ return true; } return false;
},
/**
 * 電話番号
 * ※≒num
 */
tel:function(val){
	if(/^(?:\(?\d+\)?\-?\d+\-?\d+)*$/.exec(val)){ return true; } return false;
},
/**
 * ファイル参照
 */
file:function(val){
    if ( val && val.length>0 ) { if ( /^[ 　\r\n\t]+$/.test(val) ) { return false; } else { return true; }}
},
});