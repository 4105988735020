/**
 * tool
 * @extends Klass
 */
var tool = Klass.create();

//definition start
tool.finaly({
/**
 * sliceText
 * @param {string} str
 * @param {int} str
 */
sliceText:function(str,limit){
	//通常文字以外は終了
	if(!str || str==undefined || str == null){
		return str;
	}
	
	//文字数指定なしは終了
	if(typeof limit !== "number" || limit < 0){
		return str;
	}
	
	//全角、半角でlimitを分岐
	var strJudge = unescape(escape(str).replace(/%20/g,""));
	if(strJudge.match(/[^A-Za-z]+/)){
		//全角が含まれていれば文字数調整
		limit = Math.floor(limit / 1.4);
	}
	
	//全体の文字数確認
	var strView = str;
	if(~~strView.length > limit){
		//スペースを削除
		strView = strView.substr(0,limit-1);
		strView = unescape(escape(strView).replace(/%20+$/,""))+"…";
	}
	return strView;
},
/**
 * nl2br
 * 改行コードを<br>に変換
 * @param {string} str
 * @return {string} str
 */
nl2br:function(str){
	return unescape(escape(str).replace(/%0A|%0D|%0A%0D/g,"<br>"));
},
/**
 * trim
 * 前後のtabとspaceを削除
 * @param {string} str
 * @return {string} str
 */
trim:function(str){
	return unescape(escape(str).replace(/^(%u3000|%20|%09|%0D|%0A|%0D%0A)+|(%u3000|%20|%09|%0D|%0A|%0D%0A)+$/g, ""));
},
/**
 * dump
 * オブジェクトデバッグ
 * @param {object} obj
 */
dump:function(obj) {
    var properties = '';
    for (var prop in obj){
        properties += prop + "=" + obj[prop] + "\n";
    }
    alert(properties);
},
/**
 * print
 * オブジェクトデバッグ
 * @param {object} obj
 * @return {string} properties
 */
print:function(obj) {
	try{
		var properties = '';
		var strType = this.type(obj);
		if(strType === "object"){
			properties = JSON.stringify(obj);
		}else if(strType === "array"){
			properties = JSON.stringify(obj);
		}else{
			properties = obj;
		}
		return properties;
	}catch(e){
		console.log(e.message);
	}
},
/**
 * 値の型名
 */
type:function(val){
	return Object.prototype.toString.call(val).replace(/[\[\]]|object|\s/g,"").toLowerCase();
},
/**
 * assert
 * @param {any} value
 * @param {string} msg
 */
assert:function(value,msg){
	if(!value){
		throw(msg || (value + "はtrueではない"));
	}
},
/**
 * asserteq
 * 値の厳密な比較
 */
asserteq:function(val1,val2){
	if(val1 !== val2){
		throw(msg || (val1 + "と" + val2 + "は等しくない"));
	}
},
/**
 * html escape
 * @param {string} txt
 * @return {string} txt
 */
escapeHTML:function(txt){
	txt = txt.replace(/</g, '&lt;');
    txt = txt.replace(/>/g, '&gt;');
    return txt;
},
/**
 * parseQuery
 * @param q {string} - クエリ(?x=y&z=a)
 * @return o {Object} - クエリの連想配列
 */
parseQuery:function(q){
	if(q){
		return eval(decodeURI(q.replace(/\?/,'({').replace(/=([^&]+)/g,function(){return ':"' + arguments[1] + '"'}).replace(/&/g,',') + '});'));
	}
	return {};
},
/**
 * @param {String} key 
 */
arrayKeyExists:function( key, search ) {
    if( !search || (search.constructor !== Array && search.constructor !== Object) ){
        return false;
    }
    return key in search;
},
/**
 * getExtention
 * ファイル名から拡張子取得
 * @param {string} fileName - ファイル名
 * @return {sting} 拡張子名
 */
getExtention:function(fileName) {
	  var ret;
	  if (!fileName) {
	    return ret;
	  }
	  var fileTypes = fileName.split(".");
	  var len = fileTypes.length;
	  if (len === 0) {
	    return ret;
	  }
	  ret = fileTypes[len - 1];
	  return ret;
},
/**
 * str2Array
 * 一文字ずつ配列にする
 * @param {string} str
 */
str2Array:function(str){
  var array = new Array;
  for (var i=0 ; i < str.length; i++) {
      array.push(str.charAt(i));
  }
  return array;
},
/**
 * shuffleArray
 * 配列をシャッフル
 * @param {array} a
 * @return {array} ランダムに並べ替えられた配列
 */
shuffleArray:function(a){
  var i = a.length;
  while(i){
      var j = Math.floor(Math.random()*i);
      var t = a[--i];
      a[i] = a[j];
      a[j] = t;
  }
  return a;
},
/**
 * decodeBase64
 * @param {string} s - base64
 * @return {String} デコードされた値
 */
decodeBase64:function(s) {
    var e={},i,k,v=[],r='',w=String.fromCharCode;
    var n=[[65,91],[97,123],[48,58],[47,48],[43,44]];

    for(z in n){for(i=n[z][0];i<n[z][1];i++){v.push(w(i));}}
    for(i=0;i<64;i++){e[v[i]]=i;}

    for(i=0;i<s.length;i+=72){
       var b=0,c,x,l=0,o=s.substring(i,i+72);
       for(x=0;x<o.length;x++){
         c=e[o.charAt(x)];b=(b<<6)+c;l+=6;
         while(l>=8){r+=w((b>>>(l-=8))%256);}
       }
    }
   return r;
},
/**
 * canvas2Blob
 * @param {canvas} oCanvas - キャンバスの参照
 * @param {string} strMime - MIMEtype
 */
canvas2Blob:function(oCanvas,strMime){
    var dataUrl = oCanvas.toDataURL(strMime);
    return dataURI2Blob(dataUrl.split("base64,")[1]);
},
/**
 * dataURI2Blob
 * dataURIからBlobに変換
 * @param {string} strDataURI
 */
dataURI2Blob:function(strDataURI){
	var bin = atob(strDataURI);
    var len = bin.length;
    var barr = new Uint8Array(len);
    var oBlob = "";
    for(var i = 0; i<len; i++){
        barr[i] = bin.charCodeAt(i);
    }
    
    try{
    	oBlob = new Blob([barr],{type:strMime});
    }catch(e){
	    // TypeError old chrome and FF
	    //window.BlobBuilder = window.BlobBuilder || window.WebKitBlobBuilder || window.MozBlobBuilder || window.MSBlobBuilder;
    	window.BlobBuilder = (window.WebKitBlobBuilder) ? WebKitBlobBuilder : (window.MozBlobBuilder) ? MozBlobBuilder: BlobBuilder;
	    if(e.name == 'TypeError' && window.BlobBuilder){
	    	var byteString = atob(dataUrl.split(',')[1]);
	    	var ab = new ArrayBuffer(byteString.length);
	    	var ia = new Uint8Array(ab);
	    	for (var i = 0; i < byteString.length; i++) {
	    	   ia[i] = byteString.charCodeAt(i);
	    	}
	        var bb = new BlobBuilder();
	        bb.append(ab);
	        oBlob = bb.getBlob(strMime);
	    }
	    else if(e.name == "InvalidStateError"){
	        // InvalidStateError (tested on FF13 WinXP)
	    	oBlob = new Blob([barr.buffer],{type:strMime});
	    }
	    else{
	        // We're screwed, blob constructor unsupported entirely   
	    }
    }
    
    return oBlob;
},
/**
 * canvas2ByteArray
 * 
 */
canvas2ByteArray:function(oCanvas,strMime){
    var dataUrl = oCanvas.toDataURL(strMime);
    var bin = atob(dataUrl.split("base64,")[1]);
    var len = bin.length;
    var barr = new Uint8Array(len);
    for(var i = 0; i<len; i++){
        barr[i] = bin.charCodeAt(i);
    }
    return barr;
},
/**
 * getKeycode
 * イベントオブジェクトから押下されたキーコードを取得
 * @param {event} e
 * @return {string} keycode
 */
getKeycode:function(e){
	if(document.all)
		return  e.keyCode;
	else if(document.getElementById) 
		return (e.keyCode)? e.keyCode: e.charCode;
	else if(document.layers)
		return  e.which;
},
/**
 * リサイズ画像の比率
 * getScale
 * @param {float} iPhotoWidth
 * @param {float} iPhotoHeight
 * @param {float} iWindowWidth
 * @param {float} iWindowHeight
 * @param {float} iAjust
 * @return {object} ret
 */
getScale:function(iPhotoWidth,iPhotoHeight,iWindowWidth,iWindowHeight,iAjust){
	//比率
	iAjust = iAjust?iAjust:1.1;
	var img_yx = (iPhotoHeight / iPhotoWidth);
	var img_xy = (iPhotoWidth / iPhotoHeight);
	
	//リサイズ後のサイズ取得
	var iTargetWidth = 0;
	var iTargetHeight = 0;
	
	var iPhotoArea = iPhotoWidth * iPhotoHeight;
	var iWindowArea = iWindowWidth * iWindowHeight;
	var iRatio = 1;
	
	if(iPhotoArea <= iWindowArea){
		//拡大
		if((iWindowHeight / iWindowWidth) < img_yx){
			if(iWindowWidth > iPhotoWidth){ 
				iTargetWidth = iWindowWidth;
				iTargetHeight = iWindowWidth * img_yx;
			}else{  
				iTargetWidth = iPhotoWidth;
				iTargetHeight = iPhotoWidth * img_yx;
			}
		}else{
			if(iWindowHeight > iPhotoHeight){ 
				iTargetWidth = iWindowHeight * img_xy;
				iTargetHeight = iWindowHeight;
			}else{  
				iTargetWidth = iPhotoHeight * img_xy;
				iTargetHeight = iPhotoHeight;
			}
		}
	}else{
		//縮小
		if(iPhotoWidth >= iPhotoHeight){
			//横長
			console.log("横長");
			iRatio = iWindowHeight / iPhotoHeight;
			iTargetWidth = iPhotoWidth * iRatio;
			iTargetHeight = iWindowHeight;
		}else{
			//縦長
			console.log("縦長");
			iRatio = iWindowWidth / iPhotoWidth;
			iTargetWidth = iWindowWidth;
			iTargetHeight = iPhotoHeight * iRatio;
		}
	}
	
	//補正
	iTargetWidth = iTargetWidth * iAjust;
	iTargetHeight = iTargetHeight * iAjust;
	
	var oRet = {};
	oRet["width"] = iTargetWidth;
	oRet["height"] = iTargetHeight;
	oRet["left"] = (iWindowWidth - iTargetWidth) / 2;
	oRet["top"] = (iWindowHeight - iTargetHeight) / 2;
	oRet["ratio"] = iWindowWidth / iTargetWidth;
	
	return oRet;
},
/**
 * getGUID
 * 簡易guid生成
 * @return {string} guid
 */
getGUID:function(){
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g,function(c){
		var r = Math.random() * 16 | 0, v = c == "x" ? r : (r&0x3|0x8);
		return v.toString(16);
	}).toUpperCase();
},
/**
 * getRandomString
 * ランダムな文字列を生成
 * @param i {int} - 文字数
 */
getRandomString:function(i){
	var iLen = -1 * i;
	return Math.random().toString(36).slice(iLen);
},
/**
 * getUA
 * userAgent取得
 * @return {object} ua
 */
getUA:function(){
	var o = {"ua":"","sp":false,"machine":"","os":""};
	var strUA = "";
	var strVer = "";
	strUA = navigator.userAgent.toLowerCase();
	strVer = navigator.appVersion.toLowerCase();
	if(strUA.indexOf("chrome") != -1){
		o["ua"] = "chrome";
		if(strUA.indexOf("android") != -1){
			o["machine"] = "android";
			o["os"] = "android";
			o["sp"] = true;
		}
	}else if(strUA.indexOf("safari") != -1){
		o["ua"] = "safari";
		if(strUA.indexOf("iphone") != -1){
			o["machine"] = "iphone";
			o["sp"] = true;
			o["os"] = "ios";
		}else if(strUA.indexOf("ipad") != -1){
			o["machine"] = "ipad";
			o["sp"] = true;
			o["os"] = "ios";
		}else if(strUA.indexOf("ipod") != -1){
			o["machine"] = "ipod";
			o["sp"] = true;
			o["os"] = "ios";
		}else if(strUA.indexOf("android") != -1){
			o["machine"] = "android";
			o["ua"] = "android";
			o["sp"] = true;
			o["os"] = "android";
		}
	}else if(strUA.indexOf("applewebkit") != -1){
		o["ua"] = "safari";
		if(strUA.indexOf("iphone") != -1){
			o["machine"] = "iphone";
			o["sp"] = true;
			o["os"] = "ios";
		}else if(strUA.indexOf("ipad") != -1){
			o["machine"] = "ipad";
			o["sp"] = true;
			o["os"] = "ios";
		}else if(strUA.indexOf("ipod") != -1){
			o["machine"] = "ipod";
			o["sp"] = true;
			o["os"] = "ios";
		}else if(strUA.indexOf("android") != -1){
			o["machine"] = "android";
			o["ua"] = "android";
			o["sp"] = true;
			o["os"] = "android";
		}
	}else if(strUA.indexOf("firefox") != -1){
		o["ua"] = "firefox";
	}else if(strUA.indexOf("opera") != -1){
		o["ua"] = "opera";
	}else if(strUA.indexOf("netscape") != -1){
		o["ua"] = "netscape";
	}else if(strUA.indexOf("msie") != -1){
		o["ua"] = "ie";
		  if (strVer.indexOf("msie 6.") != -1) {
			  o["ver"] = '6';
		  } else if (strVer.indexOf("msie 7.") != -1) {
			  o["ver"] = '7';
		  } else if (strVer.indexOf("msie 8.") != -1) {
			  o["ver"] = '8';
		  } else if (strVer.indexOf("msie 9.") != -1) {
			  o["ver"] = '9';
		  } else if (strVer.indexOf("msie 10.") != -1) {
			  o["ver"] = '10';
		  }  else {
		    return 'other';
		  }
	}else if(strUA.indexOf("trident") != -1){
		o["ua"] = "ie";
		o["ver"] = '11';
	}else if(strUA.indexOf("mozilla/4") != -1){
		o["ua"] = "mozilla/4";
	}
	if(strUA.indexOf("windows") != -1){
		o["machine"] = "win";
		o["os"] = "win";
	}else if(strUA.indexOf("macintosh") != -1){
		o["machine"] = "mac";
		o["os"] = "mac";
	}
	if(strUA.indexOf("fban") != -1){
		o["sp"] = true;
	}
	if(strUA.indexOf("twitter") != -1){
		o["sp"] = true;
	}
	if(strUA.indexOf("iphone") != -1){
		o["sp"] = true;
	}
	return o;
},
getOS:function(){
	var os, ua = navigator.userAgent.toLowerCase();
	var version = "";
	var device = "";
	if (ua.match(/android/)) {
		os = "android";					// android の処理
		version = this.AndroidSversion();
		//device = getAndroidDevice();
	}
	else if (ua.match(/iphone/)) {
		os = "ios";					// iphone の処理
		//version = navigator.appVersion;
		version = this.iOSversion();
	}
	else if (ua.match(/ipad/)) {
		os = "ios";					// ipad の処理
		version = this.iOSversion();
	}
	else if (ua.match(/ipod/)) {
		os = "ios";					// ipad の処理
		version = this.iOSversion();
	}
	else if (ua.match(/windows phone/)) {
		os = "windows phone";			// windows phone の処理
		version = this.windowsPhoneSversion();
	}
	else if (ua.match(/blackberry/)) {
		os = "blackberry";				// BlackBerry の処理
	}
	else if (ua.match(/win(dows )?nt 6\.3/)) {
		os = "windows 8.1";				// Windows 8.1 の処理
	}
	else if (ua.match(/win(dows )?nt 6\.2/)) {
		os = "windows 8";				// windows 8 の処理
	}
	else if (ua.match(/win(dows )?nt 6\.1/)) {
		os = "windows 7";				// windows 7 の処理
	}
	else if (ua.match(/win(dows )?nt 6\.0/)) {
		os = "windows vista";				// windows Vista の処理
	}
	else if (ua.match(/win(dows )?nt 5\.2/)) {
		os = "windows server 2003";			// windows Server 2003 の処理
	}
	else if (ua.match(/win(dows )?(nt 5\.1|XP)/)) {
		os = "windows xp";				// windows XP の処理
	}
	else if (ua.match(/win(dows)? (9x 4\.90|ME)/)) {
		os = "windows me";				// windows ME の処理
	}
	else if (ua.match(/win(dows )?(nt 5\.0|2000)/)) {
		os = "windows 2000";				// windows 2000 の処理
	}
	else if (ua.match(/win(dows )?98/)) {
		os = "windows 98";				// windows 98 の処理
	}
	else if (ua.match(/win(dows )?nt( 4\.0)?/)) {
		os = "windows nt";				// windows nt の処理
	}
	else if (ua.match(/win(dows )?95/)) {
		os = "windows 95";				// windows 95 の処理
	}
	else if (ua.match(/mac|ppc/)) {
		os = "Mac OS";					// Macintosh の処理
	}
	else if (ua.match(/linux/)) {
		os = "linux";					// Linux の処理
	}
	else if (ua.match(/^.*\s([A-Za-z]+BSD)/)) {
		os = RegExp.$1;					// BSD 系の処理
	}
	else if (ua.match(/sunos/)) {
		os = "solaris";					// Solaris の処理
	}
	else {
		os = "N/A";					// 上記以外 OS の処理
	}
	
	var o = {};
	o.os = os;
	o.ver = version;
	o.dev = device;
	return o;
},
AndroidSversion:function() {  
	var ua = navigator.userAgent;  
	if( ua.indexOf("Android") > 0 ){  
		var androidversion = parseFloat(ua.slice(ua.indexOf("Android")+8));  
		return androidversion;  
	}  
},  
iOSversion:function() {  
	if (/iP(hone|od|ad)/.test(navigator.platform)) {  
		var v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);  
		return parseInt(v[1], 10);
		//return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];  
	}  
},
windowsPhoneSversion:function() {  
	if( ua.indexOf("Windows Phone") > 0 ){
		var v = parseFloat(ua.slice(ua.indexOf("Windows Phone")+8));  
		return v;  
	}  
},
isDate:function(y,m,d){
	var dt=new Date(y,m-1,d);
	return (dt.getFullYear()==y && dt.getMonth()==m-1 && dt.getDate()==d);
}
});