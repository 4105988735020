if (!('createjs' in window)) {
	var createjs;
}
if (!('AdobeAn' in window)) {
	var AdobeAn;
}
/**
 * An
 * AnimateのHTML5出力用テンプレート
 * @class JqSprite
 * @returns
 */
var An = Klass.create();

//definition start finaly
An.extend(JqSprite);
An.finaly({
/**
 * init
 */
init:function($o,strId,strCompId,strAssetPath){
	this.o = $o;
	this.id = strId;
	this.compid = strCompId;
	this.asset_path = strAssetPath;
	this.anim = false;
	this.comp;
	this.lib;
	this.loader;
	this.canvas;
	this.stage;
	this.exportRoot;
	this.anim_container;
	this.dom_overlay_container;
},
/**
 * set
 */
set:function(){
	this.setAn.apply(this,[]);
	this.setObject.apply(this,[]);
	this.setState.apply(this,[]);
	this.setEvent.apply(this,[]);
},
setAn:function(){
	this.setAnObject.apply(this,[]);
	this.setAnDefinition.apply(this,[this.compid]);
},
/**
 * setAnObject
 */
setAnObject:function(){
	var $Ac = $("<div/>");
	$Ac.attr({id:this.id+"_animation_container"});
	var $Doc = $("<div/>");
	$Doc.attr({id:this.id+"_dom_overlay_container"});
	var $Cvs = $("#"+this.id);
	$Ac.append($Cvs.clone());
	$Ac.append($Doc);
	$Cvs.after($Ac);
	$Cvs.remove();
	this.canvas = document.getElementById(this.id);
	this.anim_container = document.getElementById(this.id+"_animation_container");
	this.dom_overlay_container = document.getElementById(this.id+"_dom_overlay_container");
},
///////////////////////////////////////////////////////////////////////////////////
//event
///////////////////////////////////////////////////////////////////////////////////
/**
* onFileLoad
*/
onFileLoad:function(evt, comp) {
	var images=comp.getImages();
	if (evt && (evt.item.type == "image")) { images[evt.item.id] = evt.result; }
},
/**
* onFileLoadComplete
*/
onFileLoadComplete:function(evt,comp,oAsync) {
	var oSelf = this;
	//This function is always called, irrespective of the content. You can use the variable "stage" after it is created in token create_stage.
	var lib=comp.getLibrary();
	var ss=comp.getSpriteSheet();
	var queue = evt.target;
	var ssMetadata = oSelf.lib.ssMetadata;
	for(i=0; i<ssMetadata.length; i++) {
		ss[ssMetadata[i].name] = new createjs.SpriteSheet( {"images": [queue.getResult(ssMetadata[i].name)], "frames": ssMetadata[i].frames} )
	}
	oSelf.exportRoot = new lib[oSelf.id]();
	oSelf.stage = new lib.Stage(oSelf.canvas);
	//Registers the "tick" event listener.
	fnStartAnimation = function() {
		oSelf.stage.addChild(oSelf.exportRoot);
		createjs.Ticker.setFPS(oSelf.lib.properties.fps);
		createjs.Ticker.addEventListener("tick", oSelf.stage);
		oSelf.stage.stop();
		oSelf.exportRoot.gotoAndStop(0);
	}
	//Code to support hidpi screens and responsive scaling.
	function makeResponsive(isResp, respDim, isScale, scaleType) {
		var lastW, lastH, lastS=1;
		window.addEventListener('resize', resizeCanvas);
		resizeCanvas();
		function resizeCanvas() {
			var w = lib.properties.width, h = lib.properties.height;
			var iw = window.innerWidth, ih=window.innerHeight;
			var pRatio = window.devicePixelRatio || 1, xRatio=iw/w, yRatio=ih/h, sRatio=1;
			if(isResp) {
				if((respDim=='width'&&lastW==iw) || (respDim=='height'&&lastH==ih)) {
					sRatio = lastS;
				}
				else if(!isScale) {
					if(iw<w || ih<h)
						sRatio = Math.min(xRatio, yRatio);
				}
				else if(scaleType==1) {
					sRatio = Math.min(xRatio, yRatio);
				}
				else if(scaleType==2) {
					sRatio = Math.max(xRatio, yRatio);
				}
			}
			oSelf.canvas.width = w*pRatio*sRatio;
			oSelf.canvas.height = h*pRatio*sRatio;
			oSelf.canvas.style.width = oSelf.dom_overlay_container.style.width = oSelf.anim_container.style.width =  w*sRatio+'px';
			oSelf.canvas.style.height = oSelf.anim_container.style.height = oSelf.dom_overlay_container.style.height = h*sRatio+'px';
			oSelf.stage.scaleX = pRatio*sRatio;
			oSelf.stage.scaleY = pRatio*sRatio;
			lastW = iw; lastH = ih; lastS = sRatio;
			oSelf.stage.tickOnUpdate = false;
			oSelf.stage.update();
			oSelf.stage.tickOnUpdate = true;
		}
	}
	makeResponsive(true,'both',true,1);
	AdobeAn.compositionLoaded(lib.properties.id);
	fnStartAnimation();
	oAsync.publish();
},
///////////////////////////////////////////////////////////////////////////////////
//function
///////////////////////////////////////////////////////////////////////////////////
/**
* load
*/
load:function(){
	var oAsync = this.getAsyncCommand(arguments);
	this.comp=AdobeAn.getComposition(this.compid);
	this.lib=this.comp.getLibrary();
	var oSelf = this;
	this.loader = new createjs.LoadQueue(false);
	this.loader.installPlugin(createjs.Sound);
	this.loader.addEventListener("fileload", function(evt){oSelf.onFileLoad.apply(oSelf,[evt,oSelf.comp])});
	this.loader.addEventListener("complete", function(evt){oSelf.onFileLoadComplete.apply(oSelf,[evt,oSelf.comp,oAsync])});
	this.lib=this.comp.getLibrary();
	this.loader.loadManifest(this.lib.properties.manifest);
}
});

//definition start
An.extend({
/**
 */
setObject:function(){
},
/**
 */
setParam:function(){
},
/**
 */
setState:function(){
},
/**
 */
setEvent:function(){
	$(window).on("resize",this.resize);
},
/**
 * setAnDefinition
 * ①Anで出力したjsをコピペ
 * ②manifestの画像パスを変更
 * ③idをstrCompIdに変更(565DC88BB5EF534A84E7D9DC6C2BF8AEのようなAnが出力したid)
 * ※idがかぶるとエラーなのでデフォルトで出力されたものに適当に付け加えるなどする
 * ※var createjs, AdobeAn;は除く
 */
setAnDefinition:function(strCompId){
},
///////////////////////////////////////////////////////////////////////////////////
//event
///////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////
//function
///////////////////////////////////////////////////////////////////////////////////
/**
 */
show:function(i){
	if(this.anim){ return; }
	var oAsync = this.getAsyncCommand(arguments);
	var oCom = new Command();
	oCom.call(this,function(){ this.anim = true; },[],"");
	oCom.call(_cmn,_cmn.passiveBody,[],"");
	oCom.call(this,function(){oSelf.o.removeClass("hide");},[],"");
	oCom.call(oSelf,oSelf.resize,[],"");
	oCom.call(this,function(){oSelf.o.animate({opacity:1},i,"linear");},[],"");
	oCom.sleep(oSelf.anim_time/2);
	oCom.call(this,function(){ this.anim = false; },[]);
	if("publish" in oAsync){ oCom.callback(oAsync,oAsync.publish,[]); }
	oCom.execute();
},
/**
 */
hide:function(i){
	if(this.anim){ return; }
	var oAsync = this.getAsyncCommand(arguments);
	var oCom = new Command();
	oCom.call(this,function(){ this.anim = true; },[],"");
	oCom.call(this,function(){oSelf.o.animate({opacity:0},i,"linear");},[],"");
	oCom.sleep(oSelf.anim_time/2);
	oCom.call(this,function(){oSelf.o.addClass("hide");},[],"");
	oCom.call(oSelf,oSelf.ctrlVideo,["clearVideo"],"");
	oCom.call(_cmn,_cmn.activeBody,[],"");
	oCom.call(this,function(){ this.anim = false; },[]);
	if(oAsync && oAsync.publish){ oCom.callback(oAsync,oAsync.publish,[]); }
	oCom.execute();
},
/**
 */
resize:function(){
	var $w = $(window);
}
});

function playSound(id, loop) {
	return createjs.Sound.play(id, createjs.Sound.INTERRUPT_EARLY, 0, 0, loop);
}